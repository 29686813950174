import { useEffect, useState } from "react";
import OverallCards from "./components/overall-cards/OverallCards";
import ProcessTable from "../../components/process-table/ProcessTable";
import TopChart from "./components/top-chart/TopChart";
import Sidebar from "../../components/sidebar/Sidebar";
import * as Styles from "./styles";
import { Input, Checkbox, Button, Modal, Form, message } from "antd";

import type { CheckboxProps, FormProps } from "antd";
import { useNavigate } from "react-router-dom";
import type { ColumnsType, TableProps } from "antd/es/table";
import { ReactComponent as Filter } from "./icons/filter.svg";
import axios from "../../common/api/axios";
import CsvDownloader from "react-csv-downloader";
import GlobalTable from "../../common/global-table/GlobalTable";
import { ReactComponent as Close } from "./icons/close.svg";

interface DataType {
  skuId: string;
  vendor: string;
  quantity: number;
  description: string;
  inductQuantity: string;
  lowInventoryThreshold: number;
  quantityToTopUp: number;
}
type FieldType = {
  skuId?: string;
  quantity?: number;
  description?: string;
  imageUri?: string;
  lowInventoryThreshold?: number;
  quantityToTopUp?: number;
  notes?: string;
  inductQuantity?: number;
  vendor?: string;
  storedInCartePlus?: Boolean;
};

export default function SkuListing() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [overallData, setOverallData] = useState([]);
  const [searchDataSource, setSearchDataSource] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [allDataSource, setAllDataSource] = useState([]);
  const [showDataSource, setShowDataSource] = useState([]);
  const [locationSearch, setLocationSearch] = useState("");
  const [viewCnt, setViewCnt] = useState(0);
  const [loader, setLoader] = useState(true);
  const [checked, setChecked] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [viewLink, setViewLink] = useState(true);
  const [isOkPopup, setIsOkPopup] = useState(false);
  const [storeStatus, setStoreStatus] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const columns: ColumnsType<DataType> = [
    {
      dataIndex: "skuId",
      key: "skuId",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "skuId"
        );
        return (
          <Styles.ArrowContainer>
            Sku ID
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.skuId.localeCompare(b.skuId),
      render: (text, record) => (
        <Styles.SkuId>
          <a onClick={() => redirectDetails(text)}>{text}</a>
        </Styles.SkuId>
      ),
      defaultSortOrder: "ascend",
    },
    {
      dataIndex: "vendor",
      key: "vendor",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "vendor"
        );
        return (
          <Styles.ArrowContainer>
            Vendor
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
    },
    {
      dataIndex: "number",
      key: "quantity",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "quantity"
        );
        return (
          <Styles.ArrowContainer>
            quantity
            {sortedColumn?.order === "ascend" ? (
              <Styles.ArrowDownIcon />
            ) : (
              <Styles.ArrowUpIcon />
            )}
          </Styles.ArrowContainer>
        );
      },
      sortDirections: ["ascend", "descend", "ascend"],
      sorter: (a, b) => a.quantity - b.quantity,
      render: (x, val, index) => {
        return val.quantity
      },
    },
    {
      dataIndex: "description",
      key: "description",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Description</Styles.ArrowContainer>;
      },
    },
    {
      key: "inductQuantity",
      dataIndex: "inductQuantity",
      title: (titleProps) => {
        const sortedColumn = titleProps.sortColumns?.find(
          ({ column }) => column.key === "inductQuantity"
        );
        return <Styles.ArrowContainer>Induct Quantity</Styles.ArrowContainer>;
      },
    },
    {
      dataIndex: "lowInventoryThreshold",
      key: "lowInventoryThreshold",
      width: 230,
      title: (titleProps) => {
        return (
          <Styles.ArrowContainer>Low Inventory Threshold</Styles.ArrowContainer>
        );
      },
    },
    {
      dataIndex: "quantityToTopUp",
      key: "quantityToTopUp",
      title: (titleProps) => {
        return <Styles.ArrowContainer>Quantity To TopUp</Styles.ArrowContainer>;
      },
    },
  ];

  const getBinDetailsData = async () => {
    try {
      const res = await axios.get("MasterData/skus", {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
        if(res.data.length > 10)
        {
          setViewLink(true);
        }
        setAllDataSource(res.data);
        setShowDataSource(res.data);
        setExportData(res.data);
        setOverallData(res.data);
        let data = res.data.slice(0, 10);
        setSearchDataSource(data);
        //setSearchData(data);
        setViewCnt(20);
        setLoader(false);
      }
    } catch (error: any) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getBinDetailsData();
  }, []);

  const handleCategoryNameInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = e.target.value;
    if (newValue.trim().length === 0) {
      setLocationSearch("");
      let data = allDataSource.slice(0, 10);
      setSearchDataSource(data);
      setShowDataSource(allDataSource);
    } else {
      setLocationSearch(newValue);
    }
  };

  useEffect(() => {
    let showArr: any = [];
    for (let i in allDataSource) {
      /* let str = Object.values(searchData[i]).join(" ");
      if (str.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1)
        newArr.push(searchData[i]); */
        let str = Object.values(allDataSource[i]).join(" ");
        if (str.toLowerCase().indexOf(locationSearch.toLowerCase()) > -1)
        showArr.push(allDataSource[i]);
    }
    if (showArr.length < 10) {
      setViewLink(false);
    }
    else{
      setViewLink(true);
    }
    setShowDataSource(showArr);
    let data = showArr.slice(0, viewCnt);
    setSearchDataSource(data);
  }, [locationSearch]);

  const viewMore = () => {
    if (checked === true) {
      const filteredData = allDataSource.filter(
        (val: any) => val.inductQuantity > 0
      );
      let data = filteredData.slice(0, viewCnt);
      if (viewCnt >= allDataSource.length) {
        setViewLink(false);
      }
      setSearchDataSource(data);
      //setSearchData(data);
      setViewCnt(viewCnt + 10);
    } else {
      let data = allDataSource.slice(0, viewCnt);
      if (viewCnt >= allDataSource.length) {
        setViewLink(false);
      }
      setSearchDataSource(data);
      setSearchData(data);
      setViewCnt(viewCnt + 10);
    }
  };

  const exportcolumns = [
    {
      id: "skuId",
      displayName: "Sku ID",
    },
    {
      id: "vendor",
      displayName: "Vendor",
    },
    {
      id: "quantity",
      displayName: "Quantity",
    },
    {
      id: "description",
      displayName: "Description",
    },
    {
      id: "inductQuantity",
      displayName: "Induct Quantity",
    },
    {
      id: "lowInventoryThreshold",
      displayName: "Low Inventory Threshold",
    },
    {
      id: "quantityToTopUp",
      displayName: "Quantity To TopUp",
    },
  ];

  const onChange: CheckboxProps["onChange"] = (e) => {
    if (e.target.checked === true) {
      const filteredData = allDataSource.filter((val: any) => val.quantity > 0);
      //let data = filteredData.slice(0, 10);
      //setSearchDataSource(data);
      setChecked(true);
      setAllDataSource(filteredData);
      //setViewCnt(20);
      setExportData(filteredData);
    } else {
      setAllDataSource(overallData);
      //let data = overallData.slice(0, 10);
      //setSearchDataSource(data);
      setChecked(false);
      //setViewCnt(20);
      setExportData(overallData);
    }
  };

  const redirectDetails = (clickedItem: string) => {
    navigate(`/sku_details`, {
      state: {
        clickedItem: clickedItem,
      },
    });
  };
  const openModal = () => {
    setIsOkPopup(true);
  }
  const isCloseOkPopup = () => {
    //ohHandleOkItem(selectedOrder.id);
    setIsOkPopup(false);
  };
  const isCancelOkPopup = () => {
    setIsOkPopup(false);
  };
  
  const onFinish: FormProps<FieldType>["onFinish"] = async(values) => {
    try {
      const res = await axios.post(`MasterData/sku/`,
      values,
      {
        headers: {
          Accept: "application/json",
          tenantId: 1,
        },
      });
      if (res.status === 200) {
          form.resetFields();
          setIsOkPopup(false);
          messageApi.open({
            type: "success",
            content: "SKU details inserted Successfully!!",
            className: "success",
            style: {
              marginTop: "85vh",
            },
          });
          getBinDetailsData();
      }
    } catch (error: any) {
      if(error?.response?.data)
      {
        
        messageApi.open({
          type: "success",
          content: error?.response?.data,
          className: "success",
          style: {
            marginTop: "85vh",
          },
        });
      }
    }
  };
  
  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (errorInfo) => {
    //console.log('Failed:', errorInfo);
  };
  const changeStoreStatus: CheckboxProps['onChange'] = (e) => {
    //console.log(`checked = ${e.target.checked}`);
    setStoreStatus(e.target.checked)
  };
  /* const changeStoreStatus = () => {
    setStoreStatus()
  } */
  

  return (
    <Styles.Container>
      {/* <Styles.SubHead> */}
      <Sidebar />
      <Styles.BlockContainer>
      <GlobalTable
        uniqueRowKey="skuId"
        column={columns}
        alldata={allDataSource}
        data={searchDataSource}
        viewlink={viewLink}
        loader={loader}
        headerAction={
          <>
          <Styles.checkRow>
            <Checkbox onChange={onChange}>
              Show SKUs with qty available
            </Checkbox>
          </Styles.checkRow>
          <Styles.addBtn>
            <Button onClick={openModal}>Add SKU</Button>
          </Styles.addBtn>
          <Styles.exportCSV>
            <CsvDownloader
              filename={"sample"}
              columns={exportcolumns}
              datas={exportData}
              text="Export CSV"
            />
          </Styles.exportCSV>
          </>
        }
       
      />
      <Styles.ModalView>
      <Modal
        wrapClassName="operator"
        open={isOkPopup}
        onOk={isCloseOkPopup}
        onCancel={isCancelOkPopup}
        closable={false}
        className="modal-style"
        width={'50%'}
        footer={false}
      >
        <Styles.Head>
          Add SKU details
          <a onClick={isCancelOkPopup}><Close /></a>
          </Styles.Head>
        <Styles.Content>
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 600 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item<FieldType>
            label="SKU ID"
            name="skuId"
            rules={[{ required: true, message: 'Please input SKU ID!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label="Description"
            name="description"
            rules={[{ required: true, message: 'Please input Description!' }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item<FieldType>
            label="Quantity"
            name="quantity"
            rules={[{ required: true, message: 'Please enter quantity!' }]}
          ><Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Image URL"
            name="imageUri"
            rules={[{ required: true, message: 'Please enter Image URL!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Vendor"
            name="vendor"
            rules={[{ required: true, message: 'Please enter Vendor!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Low Inventory Threshold"
            name="lowInventoryThreshold"
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Quantity To TopUp"
            name="quantityToTopUp"
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Induct Quantity"
            name="inductQuantity"
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label="Stored In CartePlus"
            name="storedInCartePlus"
            valuePropName="checked"
            
          >
            <Checkbox onChange={changeStoreStatus} value={storeStatus}/>
          </Form.Item>
          <Form.Item<FieldType>
            label="Notes"
            name="notes"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Styles.addBtn>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Styles.addBtn>
          </Form.Item>
          
        </Form>
        </Styles.Content>
      </Modal>
      </Styles.ModalView>
      <>{contextHolder}</>
      </Styles.BlockContainer>
    </Styles.Container>
  );
}
